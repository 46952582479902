import { Moment } from 'moment';
import { ParkingGateType, ParkingSpaceTypeEnum, PeriodTypeEnum, SubSystemType } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { post, download } from '@/service/request';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { changeTreeDataFieldName, getTreeIdByDepth, timeTransformData } from '../data-factory';
import { dateFormat } from '@/filter';
import { getDateFormatByPeriodTypeEnum, periodTypeList } from '@/filter/period-type';
import { percentFormat } from '@/filter/text';
import { generateUUID } from '@/utils/base_util';

export const getEndTimeByIntervalType = (sourceEndTime: Moment, intervalType: PeriodTypeEnum) => {
    let endTime: number = null;
    switch (intervalType) {
        case PeriodTypeEnum.HOUR:
            endTime = sourceEndTime.clone().add(1, 'hour').startOf('hour').toDate().getTime();
            break;
        case PeriodTypeEnum.DAY:
            endTime = sourceEndTime.clone().add(1, 'day').startOf('day').toDate().getTime();
            break;
        case PeriodTypeEnum.WEEK:
            endTime = sourceEndTime.clone().add(1, 'week').startOf('week').toDate().getTime();
            break;
        case PeriodTypeEnum.MONTH:
            endTime = sourceEndTime.clone().add(1, 'month').startOf('month').toDate().getTime();
            break;
        // case PeriodTypeEnum.SEASON:
        //     endTime = sourceEndTime.clone().add(3, 'month').startOf('month').toDate().getTime();
        //     break;
        case PeriodTypeEnum.YEAR:
            endTime = sourceEndTime.clone().add(1, 'year').startOf('year').toDate().getTime();
            break;
        default:
            endTime = sourceEndTime.toDate().getTime();
            break;
    }
    return endTime;
};

export class ReportExtraQueryModel extends QueryModel {
    /**
     * 时间间隔统计方式
     */
    intervalType: PeriodTypeEnum = PeriodTypeEnum.HOUR;

    /**
     * 是否合并
     */
    merge: boolean = false;

    /**
     * 是否显示图表数量
     */
    showNumber: boolean = false;

    /**
     * 额外的配置
     */
    chartExtra: Array<any> = [
        {
            key: 'intervalType',
            type: 'select',
            options: periodTypeList,
            label: '按小时',
            onchange: (value, vm) => {
                vm.chartSearchClick();
            }
        },
        {
            key: 'merge',
            type: 'checkbox',
            label: '合并显示图表',
            onchange: (value, vm) => {
                vm.chartSearchClick();
            }
        },
        {
            key: 'showNumber',
            type: 'checkbox',
            label: '显示数量',
            onchange: (value, vm) => {
                vm.showNumber(value);
            }
        }
    ];
}

export class ParkingReportQueryModel extends ReportExtraQueryModel {
    @QueryControl({
        label: '属性类型',
        type: QueryControlType.SELECT,
        options: [
            { value: 'TRAFFIC_FLOW', name: '车流量' },
            { value: 'PARKING_SPACE', name: '车位使用' }
        ],
        span: 5,
        required: true
    })
    type: string = 'PARKING_SPACE';

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: [
            { value: ParkingGateType.ENTRANCE, name: '驶入' },
            { value: ParkingGateType.EXIT, name: '驶出' }
        ],
        mode: 'multiple',
        span: 6,
        required: true,
        defaultValue: 'ALL',
        invisibleFunction: model => model.type !== 'TRAFFIC_FLOW'
    })
    trafficFlowType: Array<string> = undefined;

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: [
            { value: ParkingSpaceTypeEnum.USING, name: '占用' },
            { value: ParkingSpaceTypeEnum.FREE, name: '空闲' },
            { value: ParkingSpaceTypeEnum.RATE, name: '停车率' }
        ],
        mode: 'multiple',
        span: 6,
        required: true,
        defaultValue: 'ALL',
        invisibleFunction: model => model.type !== 'PARKING_SPACE'
    })
    parkingSpaceType: Array<string> = undefined;

    @QueryControl({
        label: '区域选择',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.PARKING,
        mode: 'multiple',
        selectChildren: true,
        span: 6,
        defaultValue: 'ALL',
        required: true
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 7,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    toService() {
        const params = {
            locationIds: this.locationIds,
            startTime: this.dateRange[0].toDate().getTime(),
            endTime: getEndTimeByIntervalType(this.dateRange[1], this.intervalType),
            merge: this.merge,
            intervalType: this.intervalType
        };
        let paramsExtra = null;
        if (this.type === 'PARKING_SPACE') {
            paramsExtra = {
                types: this.parkingSpaceType
            };
        } else if (this.type === 'TRAFFIC_FLOW') {
            paramsExtra = {
                types: this.trafficFlowType
            };
        }
        return Object.assign(params, paramsExtra);
    }
}

function getParkingSpaceTypeCode(typeEnum: ParkingSpaceTypeEnum | string): {code: string, name: string, customRender?: Function} {
    let typeObj: any = {};
    switch (typeEnum) {
        case ParkingSpaceTypeEnum.FREE:
            typeObj = {
                code: 'freeCount',
                name: '空闲'
            };
            break;
        case ParkingSpaceTypeEnum.USING:
            typeObj = {
                code: 'usingCount',
                name: '占用'
            };
            break;
        case ParkingSpaceTypeEnum.RATE:
            typeObj = {
                code: 'rate',
                name: '停车率',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            };
            break;
    }
    return typeObj;
}

function getParkingGateTypeCode(typeEnum: ParkingGateType | string): {code: string, name: string} {
    let typeObj: any = {};
    switch (typeEnum) {
        case ParkingGateType.ENTRANCE:
            typeObj = {
                code: 'inCount',
                name: '驶入'
            };
            break;
        case ParkingGateType.EXIT:
            typeObj = {
                code: 'outCount',
                name: '驶出'
            };
            break;
    }
    return typeObj;
}

export async function ParkingReportService(queryModel: ParkingReportQueryModel) {
    let url = null;
    const columns = [{ code: 'ts', name: '时间' }];
    let hasRateColumn: boolean = false;
    const tableColumns: Array<any> = [{
        title: '时间',
        dataIndex: 'name',
        customRender: (text, record, index) => {
            return dateFormat(record.ts, getDateFormatByPeriodTypeEnum(queryModel.intervalType)) || record.locationName;
        }
    }];
    if (queryModel.type === 'PARKING_SPACE') {
        url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingSpace/hts/usage`;
        tableColumns.push(..._.map(queryModel.parkingSpaceType, item => {
            const columnItem = getParkingSpaceTypeCode(item);
            columns.push(columnItem);
            if (columnItem?.code === 'rate') {
                hasRateColumn = true;
            }
            return {
                title: columnItem?.name,
                dataIndex: columnItem?.code,
                customRender: columnItem.customRender
            };
        }));
    } else if (queryModel.type === 'TRAFFIC_FLOW') {
        url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingGate/hts/trafficFlow`;
        tableColumns.push(..._.map(queryModel.trafficFlowType, item => {
            const columnItem = getParkingGateTypeCode(item);
            columns.push(columnItem);
            return {
                title: columnItem?.name,
                dataIndex: columnItem?.code
            };
        }));
    }
    const hasNumberAxis = _.filter(columns, item => item.code !== 'rate').length > 1;
    const res = await post(url, queryModel?.toService());
    res.locationName = '合计';
    res.locationId = 'id_all';
    let resData = null;
    if (res.data && res.data.length > 0) {
        resData = [res];
    } else {
        resData = res.children;
    }
    const chartData = timeTransformData(resData, columns, getDateFormatByPeriodTypeEnum(queryModel.intervalType), 'locationName');
    const chartOptions = {
        data: chartData,
        settings: {
            type: 'line',
            // yAxisType: ['normal', 'percent'],
            // yAxisName: [hasNumberAxis ? '数量' : '', '百分比(%)'],
            // axisSite: hasRateColumn ? { right: _.filter(chartData.columns, item => item.indexOf('停车率') > -1) } : {}
            yAxisType: hasNumberAxis ? ['normal', 'percent'] : ['percent'],
            yAxisName: hasNumberAxis ? ['数量', '百分比(%)'] : ['百分比(%)'],
            axisSite: hasRateColumn ? { right: hasNumberAxis ? _.filter(chartData.columns, item => item.indexOf('率') > -1) : null } : {}
        },
        extend: {
            series: {
                type: 'line',
                showSymbol: queryModel.showNumber,
                label: {
                    show: queryModel.showNumber,
                    position: 'top',
                    formatter: function(params, index) {
                        if (params.seriesName.indexOf('率') > -1) {
                            return percentFormat(_.get(params, 'value[1]'));
                        }
                        return _.get(params, 'value[1]', 0);
                    }
                }
            },
            yAxis: [
                {
                    splitLine: {
                        lineStyle: {
                            type: 'solid'
                        }
                    }
                },
                {
                    show: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                }
            ]
        },
        tableRowKey: (record) => {
            return record.locationId || generateUUID();
        },
        tableExpandedRowKeys: getTreeIdByDepth([res], 'locationId', 2),
        tableRows: changeTreeDataFieldName([res], 'data', 'children'),
        tableColumns
    };
    return assignDefaultExtend(chartOptions);
}

export async function ParkingReportExportService(queryModel: ParkingReportQueryModel) {
    let url = null;
    if (queryModel.type === 'PARKING_SPACE') {
        url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingSpace/hts/export/usage`;
    } else if (queryModel.type === 'TRAFFIC_FLOW') {
        url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingGate/export/trafficFlow`;
    }
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
