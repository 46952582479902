import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import { download } from '@/service/request';
import { ChartRingExtend } from '@/mixins/report-chart-base';
import { Moment } from 'moment';
import AccessControlIndexService from '../service/access-control-index';
import { dateFormat } from '@/filter';

export class AccessControlRtsQueryModel extends QueryModel {
    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true,
        disabled: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    toService() {
        return {
            startTime: this.dateRange[0].toDate().getTime(),
            endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime()
        };
    }
}

export async function AccessControlRtsService(model: AccessControlRtsQueryModel) {
    const res = await AccessControlIndexService.getRtsTotalCount();
    const chartOptions = {
        title: '园内人数统计',
        data: res,
        settings: {
            type: 'line'
        },
        extend: ChartRingExtend,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '时间',
                dataIndex: 'ts',
                customRender: (text, record, index) => {
                    return dateFormat(text, 'YYYY-MM-DD HH:mm');
                }
            },
            {
                title: '数量',
                dataIndex: '数量'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function AccessControlRtsExportService(queryModel: AccessControlRtsQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/accessControlSystem/rts/export/num`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
