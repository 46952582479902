import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import { get, download } from '@/service/request';
import { ChartRingExtend } from '@/mixins/report-chart-base';
import { percentFormat } from '@/filter';
import { Moment } from 'moment';

export class LightFacilityStatusQueryModel extends QueryModel {
    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true,
        disabled: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    toService() {
        return {
            startTime: this.dateRange[0].toDate().getTime(),
            endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime()
        };
    }
}

export async function LightFacilityStatusService(model: LightFacilityStatusQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem/facility/rts/status/count`;
    const res = await get(url);
    const chartOptions = {
        title: '设备状态统计',
        data: {
            columns: ['name', 'value', 'rate'],
            rows: res
        },
        settings: {
            type: 'ring'
        },
        extend: ChartRingExtend,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '项目',
                dataIndex: 'name'
            },
            {
                title: '数值',
                dataIndex: 'value'
            },
            {
                title: '占比',
                dataIndex: 'rate',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function LightFacilityStatusExportService(queryModel: LightFacilityStatusQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem/facility/rts/export/status/count`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
