import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import { PeriodTypeEnum } from '@/model/enum';
import FireIndexService from '../service/fire-index';
import { download } from '@/service/request';

export class FireLocationAlarmQueryModel extends QueryModel {
    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        return {
            startTime: this.dateRange[0].toDate().getTime(),
            endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime(),
            intervalType: PeriodTypeEnum.DAY
        };
    }
}

export async function FireLocationAlarmService(model: FireLocationAlarmQueryModel) {
    const res = await FireIndexService.getLocationAlarmCount(model?.toService());
    const chartOptions = {
        title: '告警分布统计',
        data: res.data,
        settings: Object.assign({ type: 'histogram' }, res.settings),
        extend: res.extend,
        tableRowKey: 'locationId',
        tableColumns: [
            {
                title: '名称',
                dataIndex: 'locationName'
            },
            {
                title: '已处理',
                dataIndex: 'solvedCount'
            },
            {
                title: '未处理',
                dataIndex: 'unsolvedCount'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function FireLocationAlarmExportService(queryModel: FireLocationAlarmQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/alarm/hts/export/countByLocation`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
